import { BUNDLES } from './store'

/**
 * API server URL.
 *
 * @type {string}
 */
export const SERVER_URL = 'https://back.gsclass.ru'

/**
 * API base URL.
 *
 * @type {string}
 */
const API_URL = SERVER_URL + '/api'

/**
 * Endpoint URLs.
 *
 * @type {{}}
 */
export const URLS = {
  // Redux bundles.
  [BUNDLES.GET_STUDENT]: API_URL + '/student/:code',
  [BUNDLES.GET_STUDENT_CLASSROOM]: API_URL + '/student/:code/classroom',
  [BUNDLES.GET_STUDENT_CALENDAR]: API_URL + '/student/:code/calendar',
  [BUNDLES.GET_STUDENT_COURSES]: API_URL + '/student/:code/courses',
  [BUNDLES.GET_STUDENT_COURSE]: API_URL + '/student/:code/course/:id',
  [BUNDLES.GET_STUDENT_COURSE_TOPIC]: API_URL + '/student/:code/topic/:id',
  [BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG]: API_URL + '/student/:code/topic/:id/log',
  [BUNDLES.GET_STUDENT_DAY]: API_URL + '/student/:code/day',
  [BUNDLES.GET_STUDENT_EXAMS]: API_URL + '/student/:code/exams',
  [BUNDLES.GET_STUDENT_FEEDBACK]: API_URL + '/student/:code/feedback',
  [BUNDLES.GET_STUDENT_LOG]: API_URL + '/student/:code/log',
  [BUNDLES.GET_STUDENT_MEDIA]: API_URL + '/student/:code/media',
  [BUNDLES.GET_STUDENT_SCHEDULE]: API_URL + '/student/:code/schedule',
  [BUNDLES.GET_STUDENT_SUMMARY]: API_URL + '/student/:code/summary',
  [BUNDLES.GET_STUDENT_REPORT_STUDY]: API_URL + '/student/:code/report/study',
  [BUNDLES.GET_STUDENT_TASKS]: API_URL + '/student/:code/tasks',
  [BUNDLES.GET_STUDENT_TASK]: API_URL + '/student/:code/tasks/:id',
  [BUNDLES.GET_STUDENT_DOCUMENTS]: API_URL + '/student/:code/documents',
  [BUNDLES.FORM_STUDENT_TASK]: API_URL + '/student/:code/tasks/:id/answer',
}
