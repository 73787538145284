import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleQuestion,
  faFile,
  faFilePdf
} from '@fortawesome/pro-duotone-svg-icons'

import { DataStatus } from 'ps/components'
import { BUNDLES, MONTHS } from 'ps/constants'
import { useBundle, useLoadTempEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders a student exams page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentExams () {
  const { code } = useStudent()

  useLoadTempEffect(BUNDLES.GET_STUDENT_EXAMS, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои экзамены
        </div>
        <DataStatus bundle={BUNDLES.GET_STUDENT_EXAMS}>
          <Content/>
        </DataStatus>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_EXAMS)

  return (
    <div className="card-body">
      {data.map((row, index) => {
        const { date_year, date_month, items } = row
        return (
          <div key={index} className="mb-4 table-responsive">
            <table className="table table-striped caption-top">
              <caption className="text-capitalize">{MONTHS[date_month - 1]} {date_year}</caption>
              <tbody>
              {items.map(item => {
                const { id, activity, score, note } = item
                const popover = (
                  <Popover>
                    <Popover.Header as="h3">
                      {activity['subject']['title']}
                    </Popover.Header>
                    <Popover.Body className="mb-0">
                      <div dangerouslySetInnerHTML={{ __html: activity['body'] || '<em>Нет описания</em>' }}/>
                      {activity['documents'] && (
                        <div className="mt-3">
                          {activity['documents'].map(document => {
                            const { description, filename, size, type, url } = document
                            const name = description || filename
                            let icon = faFile
                            if (type === 'application/pdf') {
                              icon = faFilePdf
                            }
                            return (
                              <div className="mb-2">
                                <FontAwesomeIcon
                                  icon={icon}
                                  fixedWidth={true}
                                  className="me-1 text-secondary"
                                />
                                <a href={url} type={type} className="text-decoration-none" target="_blank">{name}</a> ({size})
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </Popover.Body>
                  </Popover>
                )
                return (
                  <tr key={id}>
                    <td>
                      {activity['title']}
                      <OverlayTrigger trigger="click" overlay={popover} rootClose>
                        <FontAwesomeIcon icon={faCircleQuestion}className="ms-2"/>
                      </OverlayTrigger>
                      <div className="small text-muted">{note}</div>
                    </td>
                    <td className="text-end">
                      {score || 0}/{activity['score_max']}
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        )
      })}
    </div>
  )
}
