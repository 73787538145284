import React from 'react'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's schedule page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentSchedule () {
  const { code } = useStudent()

  useLoadPermEffect(BUNDLES.GET_STUDENT_SCHEDULE, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои объявления
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_SCHEDULE}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_SCHEDULE)
  const { groups } = data

  if (groups) {
    const labels = {
      schedule: 'Расписание',
      electives: 'Факультативы',
      curriculum: 'Программа обучения',
      literature: 'Рекомендуемая литература',
    }
    return Object.keys(groups).map(group => (
      <div key={group} className="mb-3">
        <h3>{labels[group]}</h3>
        <div className="row g-3">
          {groups[group].map(image => {
            const { fid, alt, title, url } = image
            return (
              <div key={fid} className="col-12 col-xl-6">
                <a href={url} target="_blank" download={true}>
                  <img src={url} alt={alt} title={title} className="img-fluid img-thumbnail"/>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    ))
  }

  return null
}
