import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's classroom.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentClassroom () {
  const { code } = useStudent()

  useLoadPermEffect(BUNDLES.GET_STUDENT_CLASSROOM, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои одноклассники
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_CLASSROOM}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_CLASSROOM)

  return (
    <Accordion defaultActiveKey={0}>
      {data.map((item, index) => {
        const { id, title } = item
        return (
          <Accordion.Item key={id} eventKey={index}>
            <Accordion.Header>
              {title}
            </Accordion.Header>
            <Accordion.Body>
              <Classroom data={item}/>
            </Accordion.Body>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}

/**
 * Renders a single classroom data.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function Classroom (props) {
  const { data } = props
  const { tutors, students } = data
  const hasTutors = !!tutors

  return (
    <Fragment>
      {hasTutors && (
        <Fragment>
          <h5 className="card-title">Тьюторы</h5>
          {tutors.map(item => (
            <div key={item.id}>{item.title}</div>
          ))}
        </Fragment>
      )}
      {!!students && (
        <Fragment>
          <h5 className={'card-title' + (hasTutors ? ' mt-4' : '')}>Ученики</h5>
          <div style={{ columns: '250px 3' }}>
            {students.map(item => (
              <div key={item.id}>
                {item['forename']} {item['surname']}
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Classroom.propTypes = {
  data: PropTypes.object.isRequired
}
