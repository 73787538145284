import React from 'react'
import PropTypes from 'prop-types'
import { Routes, Route, useParams, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faListTimeline,
  faCalendarDays,
  faPhotoFilm,
  faTable,
  faGraduationCap,
  faPenToSquare,
  faChartSimple,
  faHundredPoints,
  faAddressCard
} from '@fortawesome/pro-duotone-svg-icons'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, usePageTitle, useStudent } from 'ps/hooks'

import { StudentCalendar } from './StudentCalendar'
import { StudentClassroom } from './StudentClassroom'
import { StudentCourses } from './StudentCourses'
import { StudentCourseTopic } from './StudentCourseTopic'
import { StudentExams } from './StudentExams'
import { StudentFeedback } from './StudentFeedback'
import { StudentLog } from './StudentLog'
import { StudentMedia } from './StudentMedia'
import { StudentReports } from './StudentReports'
import { StudentReportStudy } from './StudentReportStudy'
import { StudentSchedule } from './StudentSchedule'
import { StudentTasks } from './StudentTasks'
import { StudentTask } from './StudentTask'

/**
 * Renders page container that provides student data.
 *
 * @return {*}
 * @constructor
 */
export function Student () {
  const { code } = useParams()

  // Load student data.
  useLoadPermEffect(BUNDLES.GET_STUDENT, null, { code })

  return (
    <main className="main my-3 my-lg-4">
      <div className="container">
        <DataStatus bundle={BUNDLES.GET_STUDENT}>
          <Content/>
        </DataStatus>
      </div>
    </main>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Student.propTypes = {
  code: PropTypes.string
}

/**
 * Renders dashboard page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { forename, surname } = useStudent()
  const params = useParams()
  const isRoot = !params['*']

  usePageTitle(forename + ' ' + surname)

  return (
    <div className="row">
      <div className={'col-12 col-md-auto' + (isRoot ? '' : ' d-none d-md-block')}>
        <Sidebar/>
      </div>
      <div className={'col ' + (isRoot ? 'd-none d-md-flex' : 'd-flex')}>
        <Routes>
          <Route path="classroom" element={<StudentClassroom/>}/>
          <Route path="schedule" element={<StudentSchedule/>}/>
          <Route path="log" element={<StudentLog/>}/>
          <Route path="calendar" element={<StudentCalendar/>}/>
          <Route path="media" element={<StudentMedia/>}/>
          <Route path="courses">
            <Route index element={<StudentCourses/>}/>
            <Route path="topic/:id" element={<StudentCourseTopic/>}/>
          </Route>
          <Route path="exams" element={<StudentExams/>}/>
          <Route path="reports">
            <Route index element={<StudentReports/>}/>
            <Route path="study" element={<StudentReportStudy/>}/>
          </Route>
          <Route path="tasks">
            <Route index element={<StudentTasks/>}/>
            <Route path=":id" element={<StudentTask/>}/>
          </Route>
          <Route path="feedback" element={<StudentFeedback/>}/>
          <Route path="*" element={<Default/>}/>
        </Routes>
      </div>
    </div>
  )
}

/**
 * Renders a student sidebar.
 *
 * @constructor
 */
function Sidebar () {
  const { code } = useStudent()

  // Load student summary.
  useLoadPermEffect(BUNDLES.GET_STUDENT_SUMMARY, null, { code })

  return (
    <div className="card sidebar">
      <nav className="nav flex-column border-top py-2">
        <NavLink to="classroom" className="nav-link d-flex justify-content-between text-nowrap">
          <span>
            <FontAwesomeIcon icon={faUsers} fixedWidth={true} className="me-2"/>
            Мои одноклассники
          </span>
        </NavLink>
        <NavLink to="schedule" className="nav-link d-flex justify-content-between text-nowrap">
          <span>
            <FontAwesomeIcon icon={faTable} fixedWidth={true} className="me-2"/>
            Мои объявления
          </span>
        </NavLink>
        <MenuItemLog/>
        <NavLink to="calendar" className="nav-link d-flex justify-content-between text-nowrap">
          <span>
            <FontAwesomeIcon icon={faCalendarDays} fixedWidth={true} className="me-2"/>
            Мой календарь
          </span>
        </NavLink>
        <MenuItemMedia/>
        <MenuItem
          path="courses"
          title="Мои знания"
          icon={faGraduationCap}
        />
        <MenuItem
          path="exams"
          title="Мои экзамены"
          icon={faHundredPoints}
        />
        <MenuItem
            path="reports"
            title="Мои отчёты"
            icon={faChartSimple}
        />
        <MenuItemFeedback/>
        {/*<MenuItemTasks/>*/}
      </nav>
    </div>
  )
}

/**
 * Renders the "My log" menu item.
 *
 * @return {JSX.Element}
 * @constructor
 */
function MenuItemLog () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_SUMMARY)

  return (
    <MenuItem
      path="log"
      title="Моя хроника"
      count={!!data && data['log'] && data['log']['recent'] || 0}
      icon={faListTimeline}
    />
  )
}

/**
 * Renders the "My media" menu item.
 *
 * @return {JSX.Element}
 * @constructor
 */
function MenuItemMedia () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_SUMMARY)

  return (
    <MenuItem
      path="media"
      title="Мои фото и видео"
      count={!!data && data['media'] && data['media']['recent'] || 0}
      icon={faPhotoFilm}
    />
  )
}

/**
 * Renders the "My tasks" menu item.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
function MenuItemTasks () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_SUMMARY)

  return (
    <MenuItem
      path="tasks"
      title="Мои задания"
      count={data && data['tasks'] && data['tasks']['active'] || 0}
      icon={faPenToSquare}
    />
  )
}

/**
 * Renders the "My Feedback" menu item.
 *
 * @return {JSX.Element}
 * @constructor
 */
function MenuItemFeedback () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_SUMMARY)

  if (!data || !data['feedback'] || !data['feedback']['total']) {
    return null
  }

  return (
    <MenuItem
      path="feedback"
      title="Мои отзывы"
      icon={faAddressCard}
    />
  )
}

/**
 * Renders a single sidebar menu item.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function MenuItem (props) {
  const { path, title, count, icon } = props
  return (
    <NavLink
      to={path}
      className="nav-link d-flex justify-content-between align-items-center"
    >
      <span className="text-nowrap me-3">
        <FontAwesomeIcon icon={icon} fixedWidth={true} className="me-2"/>
        {title}
      </span>
      {!!count && (
        <span className="badge bg-danger rounded-pill">+{count}</span>
      )}
    </NavLink>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
MenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  icon: PropTypes.object
}

/**
 * Renders the default page content.
 *
 * @return {JSX.Element}
 * @constructor
 */
function Default () {
  return (
    <div className="d-none d-md-block mx-auto align-self-center text-center text-500 fst-italic display-3">
      Всё возможно. На невозможное просто требуется больше времени
    </div>
  )
}
