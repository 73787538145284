/**
 * @file
 * Store constants.
 */

/**
 * Common Redux action types.
 *
 * @type {{}}
 */
export const ACTION_TYPES = {
  RESET: 'RESET',
  UPDATE: 'UPDATE'
}

/**
 * Form-specific action types.
 *
 * @type {{}}
 */
export const FORM_ACTION_TYPES = {
  SET_VALUES: 'SET_VALUES',
  SET_ERRORS: 'SET_ERRORS'
}

/**
 * Names of actions specific for "load/get" Redux bundles.
 *
 * @type {{}}
 */
export const LOAD_ACTION_TYPES = {
  DATA_MERGE: 'DATA_MERGE'
}

/**
 * Initial state of default form Redux bundle.
 *
 * @type {{}}
 */
export const FORM_INITIAL_STATE = {
  values: {},
  errors: {},
  submitting: false,
  error: null,
  result: null
}

/**
 * Initial state of default "load/get" Redux bundle.
 *
 * @type {{}}
 */
export const LOAD_INITIAL_STATE = {
  params: null,
  data: null,
  loading: false,
  loaded: false,
  error: null
}

/**
 * List of methods to handle loaded data.
 *
 * @type {{SET: string, MERGE: string}}
 */
export const DATA_HANDLE_METHODS = {
  SET: 'set',
  MERGE: 'merge'
}

/**
 * List of Redux store bundles.
 *
 * @type {{}}
 */
export const BUNDLES = {
  GET_STUDENT: 'GET_STUDENT',
  GET_STUDENT_CLASSROOM: 'GET_STUDENT_CLASSROOM',
  GET_STUDENT_CALENDAR: 'GET_STUDENT_CALENDAR',
  GET_STUDENT_COURSES: 'GET_STUDENT_COURSES',
  GET_STUDENT_COURSE: 'GET_STUDENT_COURSE',
  GET_STUDENT_COURSE_TOPIC: 'GET_STUDENT_COURSE_TOPIC',
  GET_STUDENT_COURSE_TOPIC_LOG: 'GET_STUDENT_COURSE_TOPIC_LOG',
  GET_STUDENT_DAY: 'GET_STUDENT_DAY',
  GET_STUDENT_EXAMS: 'GET_STUDENT_EXAMS',
  GET_STUDENT_FEEDBACK: 'GET_STUDENT_FEEDBACK',
  GET_STUDENT_LOG: 'GET_STUDENT_LOG',
  GET_STUDENT_MEDIA: 'GET_STUDENT_MEDIA',
  GET_STUDENT_SCHEDULE: 'GET_STUDENT_SCHEDULE',
  GET_STUDENT_SUMMARY: 'GET_STUDENT_SUMMARY',
  GET_STUDENT_REPORT_STUDY: 'GET_STUDENT_REPORT_STUDY',
  GET_STUDENT_TASKS: 'GET_STUDENT_TASKS',
  GET_STUDENT_TASK: 'GET_STUDENT_TASK',
  GET_STUDENT_DOCUMENTS: 'GET_STUDENT_DOCUMENTS',
  FORM_STUDENT_TASK: 'FORM_STUDENT_TASK',
}

/**
 * A list of load bundles and their default methods of setting data.
 *
 * @type {{[p: string]: string}}
 */
export const LOAD_BUNDLES = {
  [BUNDLES.GET_STUDENT]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_CLASSROOM]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_CALENDAR]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_COURSES]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_COURSE]: DATA_HANDLE_METHODS.MERGE,
  [BUNDLES.GET_STUDENT_COURSE_TOPIC]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_EXAMS]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_FEEDBACK]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_DAY]: DATA_HANDLE_METHODS.MERGE,
  [BUNDLES.GET_STUDENT_LOG]: DATA_HANDLE_METHODS.MERGE,
  [BUNDLES.GET_STUDENT_MEDIA]: DATA_HANDLE_METHODS.MERGE,
  [BUNDLES.GET_STUDENT_SCHEDULE]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_SUMMARY]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_REPORT_STUDY]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_TASKS]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_TASK]: DATA_HANDLE_METHODS.SET,
  [BUNDLES.GET_STUDENT_DOCUMENTS]: DATA_HANDLE_METHODS.SET,
}

/**
 * A list of form bundles.
 *
 * @type {Array}
 */
export const FORM_BUNDLES = [
  BUNDLES.FORM_STUDENT_TASK,
]
